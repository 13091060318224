@use 'typography-mixins';
@use 'colors';

.mat-form-field {
  @include typography-mixins.body3();
}

.mat-select {
  .mat-select-value {
    color: colors.$secondary;
  }
}

.mat-option {
  .mat-option-text {
    color: colors.$secondary;
    @include typography-mixins.body3();
  }
}

.add-button-menu,
.account-button-menu,
.help-button-menu {
  .mat-menu-item {
    @include typography-mixins.button();
    color: colors.$primary;
    .mat-icon {
      color: colors.$primary;
    }
  }
}

.account-button-menu {
  mat-divider:first-child {
    display: none;
  }
}

.mat-menu-panel {
  max-width: none !important;
}

.form-control-select mat-form-field {
  width: 100%;
  min-width: 250px;
}

.filter-select .form-control-select mat-form-field {
  min-width: auto;
  .mat-form-field-flex {
    padding-top: 0px;
  }
}

.organisation-select-container mat-form-field {
  width: 100%;
}

.mat-autocomplete-panel {
  background-color: colors.$background-light;
}

.fullscreen-dialog {
  .mat-dialog-container {
    padding: 0px !important;
    border-radius: 0px !important;
    background: transparent;
  }
}

.success-snackbar {
  color: colors.$text-light !important;
  background-color: colors.$green-primary !important;

  .mat-simple-snackbar-action {
    color: colors.$secondary-light !important;
  }
}

.information-snackbar {
  color: colors.$text-light !important;
  background-color: colors.$info-dark !important;

  .mat-simple-snackbar-action {
    color: colors.$info-light !important;
  }
}

.error-snackbar {
  color: colors.$text-light !important;
  background-color: colors.$warn-dark !important;

  .mat-simple-snackbar-action {
    color: colors.$secondary-light !important;
  }
}

.notification-snackbar {
  cursor: pointer;
  color: colors.$grey-dark !important;
  background-color: colors.$secondary-light !important;
  margin-top: 60px !important;
}

mat-icon.rounded-close-icon {
  display: flex !important;
}

tr.mat-row {
  height: 100px;
}

mat-row {
  height: 200px;
}

.attendees-tile mat-list-option {
  height: auto !important;
  width: auto !important;
}

mat-spinner.button-icon-loading {
  margin-right: 5px;
}
