@use '@angular/material' as mat;
@use 'colors';

/*
  How to create palettes ? cf https://medium.datadriveninvestor.com/custom-theming-in-angular-material-a9b0d7cd68a8
*/

$-primary: (
  Main: colors.$primary,
  Light: colors.$primary-light,
  Dark: colors.$primary-dark,
  contrast: (
    Main: colors.$text-light,
    Light: colors.$text-dark,
    Dark: colors.$text-light,
  ),
);

$-secondary: (
  Main: colors.$secondary,
  Light: colors.$secondary-light,
  Dark: colors.$secondary-dark,
  contrast: (
    Main: colors.$text-light,
    Light: colors.$text-dark,
    Dark: colors.$text-light,
  ),
);

$-warn: (
  Main: colors.$warn,
  Light: colors.$warn-light,
  Dark: colors.$warn-dark,
  contrast: (
    Main: colors.$text-light,
    Light: colors.$text-dark,
    Dark: colors.$text-light,
  ),
);

$primary: mat.define-palette($-primary, Main, Light, Dark);
$secondary: mat.define-palette($-secondary, Main, Light, Dark);
$warn: mat.define-palette($-warn, Main, Light, Dark);
