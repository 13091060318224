/* You can add global styles to this file, and also import other style files */

@use './themes/theme.scss';
@use 'components/buttons.scss';
@use 'components/icons.scss';
@use 'typography-mixins.scss';
@use 'components/table.scss';
@use 'override-material.scss';
@use 'override-ngx-dropzone.scss';
@use 'override-icon-color.scss';
@use 'colors';

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  @include typography-mixins.font-family();
}

button {
  @include typography-mixins.font-family();
}

.clickable {
  cursor: pointer;
}

.vertical-separator {
  border-left: 1px solid colors.$secondary-light;
  height: 24px;
}

a:not(:hover) {
  color: colors.$secondary-dark;
  text-decoration: none;
}

.required-star {
  color: colors.$warn;
}

.italic {
  font-style: italic;
}

.admin-frame {
  border: 1px dashed colors.$warn;
  border-radius: 5px;

  &.big {
    padding: 11px 16px;
    margin: 11px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    button {
      padding: 0;
    }

    a {
      font-size: 1.1em;
      font-family: 'Montserrat';
      margin: 0;
      color: colors.$warn;
      text-decoration: none;
      padding: 15px;
    }

    span {
      color: colors.$warn;
    }
  }
}
