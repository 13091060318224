@use 'colors';

table {
  tr:last-of-type td {
    border: none;
  }

  td.mat-cell {
    border-color: colors.$secondary-light;
  }
}
