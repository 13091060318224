@use 'colors';

@mixin flex-column-start-start() {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin flex-column-center-start() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

@mixin flex-column-start-center() {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-column-center-center() {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin flex-column-start-end() {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: end;
}

@mixin flex-row-center-center() {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

@mixin flex-row-center-end() {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}

@mixin flex-row-end-center() {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

@mixin flex-row-start-start() {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

@mixin flex-row-start-stretch() {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}

@mixin flex-row-start-center() {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

@mixin flex-row-center-start() {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

@mixin flex-row-space-between-center() {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

@mixin flex-row-space-between-start() {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

@mixin flex-row-start-end() {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
}

@mixin flex-column-space-between() {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@mixin main-container() {
  border-radius: 8px;
  padding: 24px;
  background-color: colors.$background-light;

  &.mobileMode {
    padding: 14px;
  }
}

@mixin section-container() {
  border: 1px solid colors.$secondary-light;
  border-radius: 8px;
  padding: 24px;

  &.mobileMode {
    padding: 14px;
  }
}

@mixin list-container() {
  border-radius: 8px;
  padding: 12px;
  background-color: colors.$background-light;
}

@mixin form-container() {
  width: 100%;
  height: 100%;
  @include flex-column-center-center();
}

@mixin nothing-to-display-message-container {
  padding: 20px 30px;
  color: colors.$primary;
  background-color: colors.$secondary-light;
  border-radius: 8px;
}
