@mixin icon-lg-size() {
  width: 22px;
  height: 22px;
  line-height: 0px;
}

@mixin icon-md-size() {
  width: 16px;
  height: 16px;
  line-height: 0px;
}

@mixin icon-sm-size() {
  width: 10px;
  height: 10px;
  line-height: 0px;
}
