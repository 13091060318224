/*
    The default configuration of each browser is 1rem = 16px.

    It's better to use rem (and em) than px for accessibility purposes.

    To help for the conversion, IDE plugin exist
*/

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400');
@mixin font-family() {
  font-family: Monserrat, sans-serif;
}

@mixin headline1() {
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1.8125rem;
}

@mixin headline2() {
  font-size: 1.125rem;
  font-weight: 700;
  line-height: 1.5625rem;
}

@mixin headline3() {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
}

@mixin headline4() {
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375rem;
}

@mixin headline5() {
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 0.875rem;
}

@mixin body1() {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
}

@mixin body2() {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem;
}

@mixin body3() {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1rem;
}

@mixin button() {
  font-size: 1rem;
  font-weight: 400;
}

@mixin statuscardBig() {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.8125rem;
}

@mixin statuscardSmall() {
  @include body3();
}
