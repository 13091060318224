@use 'colors';

@mixin icon-color($parent-classe-name, $color) {
  #{$parent-classe-name} {
    mat-icon {
      svg {
        path {
          fill: $color;
        }
      }
    }
  }
}

@include icon-color('.navigation-button', colors.$text-light);
@include icon-color('.required-document', colors.$secondary-light);
@include icon-color('.allowed-employment-type', colors.$secondary);
@include icon-color('.not-allowed-employment-type', colors.$warn);
@include icon-color('.sumup-card-main-icon', colors.$yellow-primary);
@include icon-color('.sumup-card-kpi-label.ok', colors.$green-primary);
@include icon-color('.sumup-card-kpi-label.ko', colors.$warn);
@include icon-color('.sumup-card-kpi-label.in-progress', colors.$yellow-primary);
@include icon-color('.my-employees-button', colors.$primary);
@include icon-color('.icon-labels-container.default', colors.$secondary);
@include icon-color('.icon-labels-container.ok', colors.$green-primary);
@include icon-color('.icon-labels-container.ko', colors.$warn);
@include icon-color('.icon-labels-container.in-progress', colors.$yellow-primary);
@include icon-color('.supplier-row-container', colors.$secondary);
@include icon-color('.search-container', colors.$secondary);
@include icon-color('.invitation-buttons-container', colors.$secondary);
@include icon-color('.filters-result-container', colors.$primary);
@include icon-color('.button-stroke-primary', colors.$primary);
@include icon-color('.button-basic-primary', colors.$primary);
@include icon-color('.button-stroke-warning', colors.$yellow-primary);
@include icon-color('.notification-icon.waiting', colors.$yellow-primary);
@include icon-color('.notification-icon.accepted', colors.$green-primary);
@include icon-color('.notification-icon.warn', colors.$warn);
@include icon-color('.document-status-comment-container', colors.$yellow-primary);
@include icon-color('.button-stroke-primary.ok', colors.$green-primary);
@include icon-color('.button-stroke-primary.ko', colors.$warn);
@include icon-color('.add-button-menu', colors.$primary);
@include icon-color('.site-control-info.validated', colors.$green-primary);
@include icon-color('.site-control-info.refused', colors.$warn);
@include icon-color('.control-result-icon-container', colors.$green-primary);
@include icon-color('.control-result-icon-container.control-ko', colors.$warn);
