@use 'colors';
@use 'size-mixins';
@use 'layout-mixins';

@mixin button-disabled-common() {
  color: colors.$grey-primary;
  background-color: colors.$grey-light;
  cursor: default;
}

@mixin button-flat-disabled() {
  @include button-disabled-common();
  border: none;
}

@mixin button-stroke-disabled() {
  @include button-disabled-common();
  border: 1px colors.$grey-primary solid;
}

@mixin button-icon-filled-primary {
  color: colors.$text-light;
  background-color: colors.$primary;
  border: 1px solid colors.$primary;
  box-shadow: none;
  border-radius: 50%;
  cursor: pointer;
}

.button-icon-primary {
  width: 38px;
  height: 38px;
  color: colors.$primary;
  background-color: transparent;
  border: 1px solid colors.$primary;
  box-shadow: none;
  border-radius: 50%;
  cursor: pointer;

  mat-icon {
    @include size-mixins.icon-md-size();
    display: flex;
    margin: auto;
  }

  &[disabled] {
    @include button-flat-disabled();
  }

  &:hover:not([disabled]) {
    background-color: colors.$primary;
    color: colors.$text-light;
  }
}

.button-icon-sm-stroke-primary {
  width: 24px;
  height: 24px;
  color: colors.$primary;
  background-color: transparent;
  border: 1px solid colors.$primary;
  box-shadow: none;
  border-radius: 50%;
  cursor: pointer;

  mat-icon {
    @include size-mixins.icon-sm-size();
  }

  &:hover {
    color: colors.$text-light;
    background-color: colors.$primary;
  }
}

.button-icon-sm-stroke-secondary {
  width: 24px;
  height: 24px;
  color: colors.$secondary;
  background-color: transparent;
  border: 1px solid colors.$secondary;
  box-shadow: none;
  border-radius: 50%;
  cursor: pointer;

  mat-icon {
    @include size-mixins.icon-sm-size();
  }

  &:hover {
    color: colors.$text-light;
    background-color: colors.$secondary;
  }
}

.button-icon-sm-filled-primary {
  @include button-icon-filled-primary();
  width: 24px;
  height: 24px;

  mat-icon {
    @include size-mixins.icon-sm-size();
  }
}

.button-icon-md-filled-primary {
  @include layout-mixins.flex-row-center-center();
  @include button-icon-filled-primary();
  width: 38px;
  height: 38px;

  mat-icon {
    @include size-mixins.icon-md-size();
  }
}

.button-icon-sm-filled-secondary {
  width: 24px;
  height: 24px;
  color: colors.$secondary;
  background-color: colors.$secondary-light;
  border: none;
  box-shadow: none;
  border-radius: 50%;

  mat-icon {
    @include size-mixins.icon-sm-size();
  }
}

.button-flat-primary {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  color: colors.$text-light;
  background-color: colors.$primary;
  border: none;
  box-shadow: none;
  border-radius: 4px;
  cursor: pointer;
  min-width: 95px;

  mat-icon {
    @include size-mixins.icon-md-size();
    margin-right: 8px;
  }

  &[disabled] {
    @include button-flat-disabled();
  }
}

@mixin button-stroke() {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  box-shadow: none;
  border-radius: 4px;
  cursor: pointer;

  mat-icon {
    @include size-mixins.icon-md-size();
  }

  &[disabled] {
    @include button-stroke-disabled();
  }
}

.button-stroke-secondary {
  @include button-stroke();

  color: colors.$secondary;
  background-color: transparent;
  border: 1px solid colors.$secondary-light;
}

.button-stroke-primary {
  @include button-stroke();

  color: colors.$primary;
  background-color: transparent;
  border: 1px solid colors.$primary;
}

.button-stroke-warning {
  @include button-stroke();

  color: colors.$yellow-primary;
  background-color: transparent;
  border: 1px solid colors.$yellow-primary;
}

.button-stroke-green {
  @include button-stroke();

  color: colors.$green-primary;
  background-color: transparent;
  border: 1px solid colors.$green-light;

  &:hover {
    border: 1px solid colors.$green-primary;
  }
}

.button-stroke-warn {
  @include button-stroke();

  color: colors.$warn;
  background-color: transparent;
  border: 1px solid colors.$warn-light;

  &:hover {
    border: 1px solid colors.$warn;
  }
}

.button-basic-primary {
  @include button-stroke();

  color: colors.$primary;
  background-color: transparent;
  border: none;
}

button.with-primary-dot {
  position: relative;
  &:after {
    content: ' ';
    width: 10px;
    height: 10px;
    background-color: colors.$primary;
    border-radius: 50%;
    position: absolute;
    right: 0px;
    top: 0px;
  }
}
