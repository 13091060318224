/*
  How to get the colors ? either from Figma sketch or using color calculator

  Colors calculatore : https://material.io/resources/color/#!/?view.left=0&view.right=1&primary.color=009de0&secondary.color=02729b
*/

$primary: #009ee0;
$primary-light: #62cfff;
$primary-dark: #0070ae;
$secondary: #02739b;
$secondary-light: #e7f4fb;
$secondary-dark: #00486d;
$warn: #d32929;
$warn-light: rgba($warn, 0.2);
$warn-dark: #9a0000;
$info: #0070ae;
$info-light: rgba($info, 0.5);
$info-dark: rgba($info, 0.6);

$text-dark: #000;
$text-light: #fff;

$background-light: #fff;
$background-dark: #000;

$yellow-primary: #ffbf42;
$yellow-secondary: #c98d16;
$yellow-light: rgba($yellow-primary, 0.2);
$grey-light: #bebebe;
$grey-primary: #616161;
$grey-dark: #383838;

$green-primary: #46a37e;
$green-light: rgba($green-primary, 0.2);
